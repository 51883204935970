/* eslint-disable react/jsx-key */
import './mobileSearch.css';
import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, Link, useHistory } from 'react-router-dom';

function MobileSearch() {
  const options = [
    {
      name: 'everything about Giovanni',
      value: 'all',
    },
    {
      name: 'about',
      value: 'about',
    },
    { name: 'works', value: 'works' },
    { name: 'writing', value: 'writing' },
    { name: 'images', value: 'images' },
    { name: 'social', value: 'social' },
  ];

  // Get url pathname to use as search value
  // eslint-disable-next-line no-undef
  const urlPathname = window.location.pathname;
  const rx = /[^/](.*)/g;
  const arr = rx.exec(urlPathname);
  let val = '';
  if (arr) {
    val = arr[0];
  }

  useEffect(() => {
    // Show the clear button when the search bar is nonempty. Otherwise, hide it.
    // eslint-disable-next-line no-undef
    const clearBtn = document.querySelector('.mobile-clear-icon');
    // eslint-disable-next-line no-undef
    const input = document.querySelector('.mobile-search-input').value;
    if (input) {
      clearBtn.style.display = 'none';
    } else {
      clearBtn.style.display = 'inline-block';
    }
  }, []);

  /// Remove option upon button click
  function removeOption(i) {
    // i.remove();
    i.style.display = 'none';
  }

  // Clear input field
  function clear() {
    // eslint-disable-next-line no-undef
    const input = document.querySelector('.mobile-search-input');
    input.value = '';
  }

  // Search Website by pushing search term into url
  const history = useHistory();
  const searchWebsite = () => {
    // eslint-disable-next-line no-undef
    const path = document.querySelector('.mobile-search-input').value;
    if (path) {
      history.push(path);
    }
  };

  const clearValue = () => {
    // eslint-disable-next-line no-undef
    const closeIcon = document.querySelector('.mobile-clear-icon');
    // eslint-disable-next-line no-undef
    const searchValue = document.querySelector('.mobile-search-input').value;
    if (searchValue) {
      closeIcon.style.display = 'inline-block';
    } else {
      closeIcon.style.display = 'none';
    }
  };

  useEffect(() => {
    // Trigger search when enter key is pressed
    // eslint-disable-next-line no-undef
    const inputField = document.querySelector('.mobile-search-input');
    inputField.addEventListener('keyup', (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        searchWebsite();
        // Show the enter screen, which was cropped while the search screen was active
        // eslint-disable-next-line no-undef
        document.querySelector('body').style.height = '100%';
        // eslint-disable-next-line no-undef
        document.querySelector('body').style.overflow = 'scroll';
      }
    });

    // Only show the close icon when the search bar is nonempty
    // eslint-disable-next-line no-undef
    const closeIcon = document.querySelector('.mobile-clear-icon');
    // eslint-disable-next-line no-undef
    const searchValue = document.querySelector('.mobile-search-input').value;
    if (searchValue) {
      closeIcon.style.display = 'inline-block';
    } else {
      closeIcon.style.display = 'none';
    }
  }, []);

  // Leave the mobile search area when the back icon is pressed
  function goBack() {
    // eslint-disable-next-line no-undef
    document.querySelector('.mobile-search-box').style.display = 'none';
    // Unhide the rest of the body
    // eslint-disable-next-line no-undef
    document.querySelector('body').style.height = '100%';
    // eslint-disable-next-line no-undef
    document.querySelector('body').style.overflow = 'scroll';
  }

  function useOutsideHandler(ref) {
    useEffect(() => {
      // Unhide the rest of the body when user leaves the mobile search area
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // eslint-disable-next-line no-undef
          document.querySelector('body').style.height = '100%';
          // eslint-disable-next-line no-undef
          document.querySelector('body').style.overflow = 'scroll';
        }
      }

      // eslint-disable-next-line no-undef
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // eslint-disable-next-line no-undef
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperSearchRef = useRef(null);
  useOutsideHandler(wrapperSearchRef);

  return (
    <div className="mobile-search-box">
      <div className="mobile-search-cont">
        <div className="mobile-search">
          <div className="mobile-search-value" ref={wrapperSearchRef}>
            <FontAwesomeIcon
              className="searchbar-icon back-icon"
              icon={faArrowLeft}
              onClick={goBack}
            />
            <input
              placeholder=" "
              autoComplete="on"
              className="mobile-search-input"
              defaultValue={val}
              onChange={clearValue}
            />
            <FontAwesomeIcon
              className="searchbar-icon mobile-clear-icon"
              icon={faTimes}
              title="Clear"
              onClick={clear}
            />
          </div>
          <div className="mobile-search-select">
            <div className="mobile-search-options">
              {options.map((option) => (
                <div className="mobile-search-option" type="button">
                  <span>
                    <span>
                      <FontAwesomeIcon className="clock-icon" icon={faClock} />
                      <Link to={`/${option.value}`}>{option.name}</Link>
                    </span>
                    <FontAwesomeIcon
                      className="delete-icon"
                      icon={faTimes}
                      title="Clear"
                      onClick={(e) => removeOption(
                        e.currentTarget.parentElement.parentElement,
                      )}
                    />
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileSearch;
