import mTwo from '../images/github-icon.png';
import workshop_rails from '../images/workshop_rails_app.png';
import railsMarketplace from '../images/marketplace_rails.png';
import crossfitwod from '../images/crossfitwod.png';
import cG from '../images/code_2_grow.png';
import sticker from '../images/sticker_smash.png';

export const ProjectsContent = [
  {
    link: 'https://gjuliao.github.io/crossfitwod/',
    img: crossfitwod,
    name: 'Crossfit WOD Generator',
    excerpt: `The Crossfit Wod generator allows users to filter what type of workout they want to do
    and you can generate as much workouts the user desires`,
    tools: 'React',
  },
  {
    link: 'https://github.com/gjuliao/workshop',
    img: workshop_rails,
    name: 'Workshop - SaaS with Stripe and admin dashboard',
    excerpt: `Rails workshop app with admin dashboard and integration with Stripe
    to charge and refund complete or partial amounts.`,
    tools: 'Ruby on Rails',
  },
  {
    link: 'https://github.com/gjuliao/Rails_Marketplace',
    img: railsMarketplace,
    name: 'Rails Marketplace',
    excerpt: `This is a RoR marketplace where users can create products, 
    and can also become customer of other people products. 
    Great for a starter for any marketplace project.`,
    tools: 'Ruby on Rails · CSS',
  },
  {
    link: 'https://github.com/gjuliao/image-chatgpt',
    img: mTwo,
    name: 'Write to create Image with ChatGPT',
    excerpt: 'Image generator powered by ChatGPT API using the \'Dall-e-2\' model with Vite framework.',
    tools: 'React · ChatGPT',
  },
  {
    link: 'https://github.com/gjuliao/community-app',
    img: mTwo,
    name: 'Rails Community App',
    excerpt: `Ruby on Rails Community app with Hotwire Turbo and Stimulus. 
    Implementation of ransack, kaminari, devise, Faker and many other gems.`,
    tools: 'Ruby on Rails - Bootstrap',
  },
  {
    link: 'https://github.com/gjuliao/budget_app',
    img: mTwo,
    name: 'Budget App',
    excerpt: 'This is a RoR budget app with postgresql for users to add expenses transactions within categories.',
    tools: 'Ruby on Rails',
  },
  {
    link: 'https://github.com/gjuliao/recipe_app',
    img: mTwo,
    name: 'Recipe App',
    excerpt: 'This is a ruby on rails recipe app that allows users to add, delete and update recipes and make private or public each recipe.',
    tools: 'Ruby on Rails',
  },
  {
    link: 'https://github.com/gjuliao/code2grow_agency',
    img: cG,
    name: 'Agency Website',
    excerpt: 'Mobile and desktop web page for a software developing agency. Built as Capstone project for Microverse Module One. ',
    tools: 'JavaScript · HTML · CSS',
  },
  {
    link: 'https://github.com/gjuliao/docker-rails',
    img: mTwo,
    name: 'Docker Rails',
    excerpt: 'Docker setup for building a rails 7 app.',
    tools: 'Ruby on Rails · Docker',
  },
  {
    link: 'https://github.com/gjuliao/StickerSmash',
    img: sticker,
    name: 'Sticker Smash',
    excerpt: 'React Native app for uploading, displaying and adding emoticons to images.',
    tools: 'React Native',
  },
  {
    link: 'https://github.com/gjuliao/url_shortner',
    img: mTwo,
    name: 'Rails URL shortner',
    excerpt: 'Allows users to add a link and the app shortens and follows the amount of link clicks',
    tools: 'Ruby on Rails',
  },
];
