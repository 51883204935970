/* eslint-disable no-undef */
/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './allResultsCard.css';

function AllResultsCard({ results }) {
  const [filteredResults] = useState(results);
  function formatURL(u) {
    // Split a given url into its various parts
    const urltext = u;
    const url = new URL(urltext);
    const domain = url.origin;
    const { pathname } = url;
    let paths = pathname.split('/');
    paths = paths.filter(Boolean);
    return [domain, paths];
  }

  return (
    <div className="results-content">
      {filteredResults.map((item) => (
        <div className="result-card">
          <a href={`${item.link}`}>
            <p>
              {/* Format URl with angle brackets between parts */}
              {`${formatURL(item.link)[0]}`}
              {formatURL(item.link)[1].map((path) => (
                <span>{` › ${path}`}</span>
              )) }
            </p>
            <h3>{`${item.name}`}</h3>
          </a>
          <p className="excerpt">{`${item.excerpt}`}</p>
        </div>
      ))}
    </div>
  );
}

AllResultsCard.propTypes = {
  results: PropTypes.array,
};

export default AllResultsCard;
