export const Content = [
  {
    category: 'about',
    link: `${window.location.origin}/about#me`,
    name: 'About Giovanni',
    excerpt: `I am a tech enthusiast with +10 years of experience in the tech industry.  
    My early years in tech started with sales, later transitioned to product management as my full stack software development skills grew.`,
  },
  {
    category: 'about',
    link: `${window.location.origin}/about#skills`,
    name: 'An overview of my technical skills and interests',
    excerpt: `Product management, Jira, ClickUp, Kanban, HTML, CSS, JavaScript, React, React Native, Ruby, Ruby on Rails, Python, Open Source, AWS, 
    PostgresQl, Scrum, Agile methodologies.`,
  },
  {
    category: 'social',
    link: 'https://medium.com/@gjuliao32',
    name: 'Medium',
    excerpt: 'Connect and share experiences',
  },
  {
    category: 'social',
    link: 'https://www.linkedin.com/in/giovanni-juliao/',
    name: 'LinkedIn',
    excerpt: 'Connect and share experiences',
  },
  {
    category: 'social',
    link: 'https://github.com/gjuliao',
    name: 'GitHub',
    excerpt: 'View my projects and code.',
  },
  {
    category: 'works',
    link: 'https://gjuliao.github.io/crossfitwod/',
    name: 'Crossfit WOD Generator',
    excerpt: `React app that provides a daily WOD to users.
    Allows filtering among different type of workouts`,
  },
  {
    category: 'works',
    link: 'https://github.com/gjuliao/workshop',
    name: 'Workshop - SaaS with Stripe and admin dashboard',
    excerpt: `Rails workshop app with admin dashboard and integration with Stripe
    to charge and refund complete or partial amounts.`,
  },
  {
    category: 'works',
    link: 'https://github.com/gjuliao/image-chatgpt',
    name: 'Write to create Image with ChatGPT',
    excerpt: 'Image generator powered by ChatGPT API using the \'Dall-e-2\' model with Vite framework.',
  },
  {
    category: 'works',
    link: 'https://github.com/gjuliao/Rails_Marketplace',
    name: 'Rails Marketplace',
    excerpt: `This is a RoR marketplace where users can create products, 
    and can also become customer of other people products. 
    Great for a starter for any marketplace project.`,
  },
  {
    category: 'works',
    link: 'https://github.com/gjuliao/community-app',
    name: 'Rails Community App',
    excerpt: `Ruby on Rails Community app with Hotwire Turbo and Stimulus. 
    Implementation of ransack, kaminari, devise, Faker and many other gems.`,
  },
  {
    category: 'works',
    link: 'https://github.com/gjuliao/budget_app',
    name: 'Budget App',
    excerpt: 'This is a RoR budget app with postgresql for users to add expenses transactions within categories.',
  },
  {
    category: 'works',
    link: 'https://github.com/gjuliao/recipe_app',
    name: 'Recipe App',
    excerpt: 'This is a ruby on rails recipe app that allows users to add, delete and update recipes and make private or public each recipe.',
  },
  {
    category: 'works',
    link: 'https://github.com/gjuliao/code2grow_agency',
    name: 'Agency Website',
    excerpt: 'Mobile and desktop web page for a software developing agency. Built as Capstone project for Microverse Module One.',
  },
  {
    category: 'works',
    link: 'https://github.com/gjuliao/docker-rails',
    name: 'Docker Rails',
    excerpt: `
    Docker setup for building a rails 7 app. `,
  },
  {
    category: 'works',
    link: 'https://github.com/gjuliao/StickerSmash',
    name: 'Sticker Smash',
    excerpt: 'React Native app for uploading, displaying and adding emoticons to images. ',
  },
  {
    category: 'works',
    link: 'https://github.com/gjuliao/url_shortner',
    name: 'Rails URL shortner',
    excerpt: 'Allows users to add a link and the app shortens and follows the amount of link clicks',
  },

  {
    category: 'writing',
    link: 'https://medium.com/@gjuliao32/is-the-product-management-role-broken-9b8ec2a17f22',
    name: 'Is the product management role broken?',
    excerpt: `The motivation for writing this article consists in openning a discussion about
    the current growth trend of software developer teams talking about a broken product management 
    role and the required adjustments the market seems to be demanding for this role in 2024. `,
  },
  {
    category: 'writing',
    link: 'https://medium.com/@gjuliao32/is-the-scrum-certification-worth-it-903c65ec5175',
    name: 'Is the Scrum Certification worth it?',
    excerpt: `The purpose of writing this article is because throughout my +10 years in tech
    I have had the chance to work on multiple software development teams and discover to my surprise
    that companies apply different variations or mutations of what they name SCRUM agile framework, 
    which often result in ineffective progress. `,
  },
  {
    category: 'writing',
    link: 'https://medium.com/@gjuliao32/mastering-graphs-a-progressive-journey-d4e562a487a0',
    name: 'Mastering Graphs: A progressive journey',
    excerpt: `This article was made to facilitate the learning process of Graphs Algorithms. 
    It starts basic and then as you progress they increase the difficulty level. `,
  },
  {
    category: 'writing',
    link: 'https://medium.com/@gjuliao32/mastering-binary-trees-a-progressive-journey-7e47d128376b',
    name: 'Mastering Binary Trees: A Progressive Journey',
    excerpt: `This article was made to facilitate the learning process of Binary Trees. 
    It starts basic and then as you progress they increase the difficulty level. `,
  },
  {
    category: 'writing',
    link: 'https://medium.com/@gjuliao32/mastering-linked-lists-a-progressive-journey-e73c792373f7',
    name: 'Mastering Linked Lists: A Progressive Journey',
    excerpt: `This article was made to facitilate the learning process of LinkedLists. 
    It starts basic and then as you progress they increase the difficulty level. `,
  },
  {
    category: 'writing',
    link: 'https://medium.com/@gjuliao32/installing-bootstrap-rails-7-a-step-by-step-guide-0fc4a843d94f',
    name: 'Installing Bootstrap Rails 7: A Step-by-Step Guide',
    excerpt: `Bootstrap is a popular front-end framework that simplifies web development by providing various
    pre-designed components and styles. If you’re working with Ruby on Rails 7 and want to seamlessly integrate 
    Bootstrap into your project, you’ve come to the right place. In this tutorial, we’ll walk you through the process of installing 
    Bootstrap Rails 7 step by step. `,
  },
];
