import cvEnglish from '../images/cv_giovanni_english.png';
import cvEspanol from '../images/cv_giovanni_espanol.png';
import workshop_rails from '../images/workshop_rails_app.png';
import cG from '../images/code_2_grow.png';
import sticker from '../images/sticker_smash.png';
import mOne from '../images/medium_logo_one.png';
import li from '../images/linkedIn-icon.png';
import gh from '../images/github-icon.png';
import sb from '../images/scrum_badge.png';
import microverse from '../images/microverse_certificate.png';
import israel from '../images/israel_giovanni.jpeg';
import railsMarketplace from '../images/marketplace_rails.png';
import crossfitwod from '../images/crossfitwod.png';

export const ImagesContent = [
  {
    link: 'https://drive.google.com/file/d/1VEhslW3LnkTqd9dbzN3r6CcKlKH9Avt4/view?usp=sharing',
    img: cvEnglish,
    name: 'Resume Giovanni English',
  },
  {
    link: 'https://drive.google.com/file/d/1qv0KGOjlJvT6-v1uch2Q2OcJ_FrldsUT/view?usp=sharing',
    img: cvEspanol,
    name: 'Resume Giovanni Spanish',
  },
  {
    link: 'https://www.linkedin.com/in/giovanni-juliao/overlay/1635483595138/single-media-viewer/?profileId=ACoAAAJq5cMBzp-q4fXs6Wjr5Sp8NdfZO1Nzk0A',
    img: israel,
    name: 'Israel Innovation Experience',
  },
  {
    link: 'https://gjuliao.github.io/crossfitwod/',
    img: crossfitwod,
    name: 'Crossfit WOD Live Demo',
  },
  {
    link: 'https://bcert.me/bc/html/show-badge.html?b=qjvrmsmw',
    img: sb,
    name: 'View Scrum Certificate',
  },
  {
    link: 'https://www.credential.net/e108e8a2-b391-4593-a09f-9db4e0a0e5e2#gs.c89itx',
    img: microverse,
    name: 'View Full Stack Certificate',
  },
  {
    link: 'https://github.com/gjuliao/workshop',
    img: workshop_rails,
    name: 'Workshop Rails Bootstrap',
  },
  {
    link: 'https://github.com/gjuliao/Rails_Marketplace',
    img: railsMarketplace,
    name: 'Rails Marketplace',
  },
  {
    link: 'https://github.com/gjuliao/code2grow_agency',
    img: cG,
    name: 'Agency Code2Grow',
  },
  {
    link: 'https://github.com/gjuliao/StickerSmash',
    img: sticker,
    name: 'React Native - Sticker Smash',
  },
  {
    link: 'https://github.com/gjuliao',
    img: gh,
    name: 'GitHub - Explore my projects and code',
  },
  {
    link: 'https://www.linkedin.com/in/giovanni-juliao/',
    img: li,
    name: 'LinkedIn - Connect and share experiences',
  },
  {
    link: 'https://medium.com/@gjuliao32',
    img: mOne,
    name: 'LinkedIn - Connect and share experiences',
  },
];
