import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="footer">
      <div className="country">
        Spain
      </div>
      <div className="footer-links">
        <div className="footer-links-section">
          <Link to="/about"> About </Link>
          <Link to="/projects"> Projects </Link>
          <Link to="/blog"> Blog</Link>
          <a href="mailto:gjuliao32@gmail.com"> Email </a>
          <a href="https://medium.com/@gjuliao32"> Medium </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
