import React from 'react';
import './accordion.css';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function AccordionComponent() {
  const classes = useStyles();

  return (
    <div className="accordion-container">
      <div className="accordion-sub-container">
        <div className={classes.root}>
          <h2 style={{ fontWeight: 'normal', color: '#333' }}>
            People also ask
          </h2>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography className={classes.heading}>
                What are your major professional achievements?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Turning around a mostly failed fintech product into a successfull startup,
                which allowed me to unlock company stocks to achieve my first tech exit!
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <Typography className={classes.heading}>
                What motivates you?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Overcoming challenges, leading software development teams, learning new things,
                coming up with creative ideas to unlock growth, and making things happen!
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                What are your favourite tech tools?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Personally, I love using Github kanban boards for managing my personal weekly sprints.
                Professionaly, I prefer working with Jira for agile product management. HubSpot for CRM.
                Visual Studio code for coding. Heroku and AWS for deployment, and Appsflyer for attribution.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                What are your hobbies?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                I enjoy reading history novels, playing soccer and doing cross-fit.
                My favourites authors up till now are Ken Follett, Julia Navarro, and Haruki Murakami.
                My biggest passion is around tech, creating products and software developing.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                What do you value the most in a working environment?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                What I most value is working with a team with a growth mentality.
                Where learning and solving challenges is prefered above blaming or highlighting others failures or misfortunes.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>
                Are you open for work?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                With my business mindset and software development skills, Im looking for tech product management related
                roles requiring structured and creative solutions for growth.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography className={classes.heading}>
                Fastest way to reach you?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                LinkedIn DM or email. Contact me at gjuliao32@gmail.com.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default AccordionComponent;
