import mOne from '../images/medium_logo_one.png';
import mThree from '../images/medium_logo_three.jpg';
import pocket from '../images/pocketgamer.jpeg';

export const BlogContent = [
  {
    link: 'https://medium.com/@gjuliao32/is-the-product-management-role-broken-9b8ec2a17f22',
    img: mOne,
    icon: mThree,
    name: 'Is the product management role broken?',
    excerpt: `The motivation for writing this article consists in openning a discussion about
    the current growth trend of software developer teams talking about a broken product management 
    role and the required adjustments the market seems to be demanding for this role in 2024. `,
    category: 'Product Management',
    date: '15 hours ago',
  },
  {
    link: 'https://medium.com/@gjuliao32/is-the-scrum-certification-worth-it-903c65ec5175',
    img: mOne,
    icon: mThree,
    name: 'Is the Scrum Certification worth it?',
    excerpt: `The purpose of writing this article is because throughout my +10 years in tech
    I have had the chance to work on multiple software development teams and discover to my surprise
    that companies apply different variations or mutations of what they name SCRUM agile framework, 
    which often result in ineffective progress. `,
    date: '12 hours ago',
  },
  {
    link: 'https://medium.com/@gjuliao32/is-the-product-management-role-broken-9b8ec2a17f22',
    img: pocket,
    icon: pocket,
    name: 'Tech giants turning into the mobile gaming industry',
    excerpt: `Clever IP usage gains weight as an efficient way to succeed.  The purpose of this article is to 
    highlight the trend of entertainment and big tech companies joining the growing mobile gaming industry 
    and why non-tech companies should be interested in joining as well. `,
    category: 'Mobile Gaming',
    date: '10 hours ago',
  },
  {
    link: 'https://medium.com/@gjuliao32/mastering-graphs-a-progressive-journey-d4e562a487a0',
    img: mOne,
    icon: mThree,
    name: 'Mastering Graphs: A progressive journey',
    excerpt: `This article was made to facilitate the learning process of Graphs Algorithms. 
    It starts basic and then as you progress they increase the difficulty level. `,
    category: 'JavaScript - Algorithms',
    date: '2 days ago',
  },
  {
    link: 'https://medium.com/@gjuliao32/mastering-binary-trees-a-progressive-journey-7e47d128376b',
    img: mOne,
    icon: mThree,
    name: 'Mastering Binary Trees: A Progressive Journey',
    excerpt: `This article was made to facilitate the learning process of Binary Trees. 
    It starts basic and then as you progress they increase the difficulty level.`,
    category: 'JavaScript - Algorithms',
    date: '1 days ago',
  },
  {
    link: 'https://medium.com/@gjuliao32/mastering-linked-lists-a-progressive-journey-e73c792373f7',
    img: mOne,
    icon: mThree,
    name: 'Mastering Linked Lists: A Progressive Journey',
    excerpt: `This article was made to facitilate the learning process of LinkedLists. 
    It starts basic and then as you progress they increase the difficulty level.`,
    category: 'JavaScript - Algorithms',
    date: '1 days ago',
  },
  {
    link: 'https://medium.com/@gjuliao32/installing-bootstrap-rails-7-a-step-by-step-guide-0fc4a843d94f',
    img: mOne,
    icon: mThree,
    name: 'Installing Bootstrap Rails 7: A Step-by-Step Guide',
    excerpt: `Bootstrap is a popular front-end framework that simplifies web development by providing various
    pre-designed components and styles. If you’re working with Ruby on Rails 7 and want to seamlessly integrate 
    Bootstrap into your project, you’ve come to the right place. In this tutorial, we’ll walk you through the process of installing 
    Bootstrap Rails 7 step by step.`,
    category: 'JavaScript - Algorithms',
    date: '1 days ago',
  },
  {
    link: 'https://medium.com/@gjuliao32/installing-bootstrap-rails-7-a-step-by-step-guide-0fc4a843d94f',
    img: mOne,
    icon: mThree,
    name: 'Installing Bootstrap Rails 7: A Step-by-Step Guide',
    excerpt: `Bootstrap is a popular front-end framework that simplifies web development by providing various
    pre-designed components and styles. If you’re working with Ruby on Rails 7 and want to seamlessly integrate 
    Bootstrap into your project, you’ve come to the right place. In this tutorial, we’ll walk you through the process of installing 
    Bootstrap Rails 7 step by step.`,
    category: 'Ruby on Rails - Bootstrap',
    date: '1 days ago',
  },

];
